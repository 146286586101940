import { connect } from 'react-redux';
import { useRouter } from 'next/router';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import { getPageDataFromLocation } from 'Utils/appUtils';
import { checkIfSitemapPage, checkIfStudentDiscountPage } from 'Utils/gen';
import { getLocalizationLabels } from 'Utils/localizationUtils';
import { getHost } from 'Utils/stateUtils';
import { getApiString } from 'Utils/urlUtils';

import { changeLanguage } from 'Actions/language';

import { LANGUAGE_SORT_ORDER, PAGE_TYPE } from 'Constants/constants';
import { strings } from 'Constants/strings';
import { BLOCKED_QUERY_PARAMS } from 'Constants/url';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const StyledPickerItem = styled.div<{ $isActive: boolean }>`
	width: 100%;
	border-radius: 4px;
	height: 2rem;
	${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)}

	color: ${({ $isActive }) =>
		$isActive ? colors.BRAND_COLORS.PURPS : colors.GREY_DS.G2} !important;

	background-color: ${({ $isActive }) => $isActive && colors.GREY.FLOATING};
	&:hover {
		background-color: ${colors.GREY_DS.G8};
	}
`;
const StyledAnchor = styled.a`
	padding: 0.5rem;
	display: flex;
	flex-direction: row;
	justify-items: flex-start;
	gap: 8px;
	align-items: center;
`;

const LanguageList = (props: any) => {
	const router = useRouter();
	const {
		urlSlugsLanguageMap,
		supportedLanguages,
		languageMap,
		paramLang,
		currentPage,
		onChangeLanguage,
		languageCode,
		location,
		onAfterLanguageSelect,
		host,
	} = props;

	const isStudentDiscountPage = checkIfStudentDiscountPage(currentPage);
	const isSitemapPage = checkIfSitemapPage(currentPage);

	const onLanguageItemClick = async ({
		isFallback,
		languageCode,
		url,
	}: any) => {
		onChangeLanguage({
			code: languageCode,
		});

		const localizedStrings = await getLocalizationLabels({
			lang: languageCode,
		});

		strings.setContent({
			default: localizedStrings,
		});

		onAfterLanguageSelect?.();

		if (isFallback) router.push(url);
	};

	// @ts-expect-error TS(7006): Parameter 'code' implicitly has an 'any' type.
	const getLanguageUrl = (paramLang = 'en', code) => {
		const url = new URL('https://' + host + location?.asPath);

		BLOCKED_QUERY_PARAMS.forEach(q => url.searchParams.delete(q));

		// If we have URl slug map, return URL Slug for particular language.
		// This is computed on backend
		if (urlSlugsLanguageMap) {
			const urlSlug = urlSlugsLanguageMap?.[getApiString(code)];
			return urlSlug + url.search;
		}

		const pathHasEnglishLangToken = location.href.indexOf('/en/') > -1;
		if (paramLang === 'en' && code !== 'en' && !pathHasEnglishLangToken) {
			return `/${code}${url.pathname + url.search}`;
		}

		return `${url.pathname + url.search}`.replace(
			`/${paramLang}/`,
			`/${code}/`,
		);
	};

	languageMap.sort(
		(aL: any, bL: any) =>
			LANGUAGE_SORT_ORDER.indexOf(aL.code) -
			LANGUAGE_SORT_ORDER.indexOf(bL.code),
	);

	return languageMap.map((language: any) => {
		const { code, displayName } = language;
		const isFallback = !(isStudentDiscountPage || isSitemapPage)
			? !supportedLanguages?.includes(code?.toUpperCase?.())
			: false;
		const url = getLanguageUrl(paramLang, code);

		return (
			<StyledPickerItem
				onClick={() =>
					onLanguageItemClick({
						isFallback,
						url,
						languageCode: code,
					})
				}
				key={code}
				$isActive={code === languageCode}
			>
				<StyledAnchor
					href={isFallback ? undefined : url}
					as={isFallback ? 'div' : 'a'}
					className='core-language-link block'
				>
					{displayName}
				</StyledAnchor>
			</StyledPickerItem>
		);
	});
};

const mapStateToProps = (state: any, ownProps: any) => {
	const { location } = ownProps;
	const languageMap = state?.languageStore?.languages;
	const pageData = getPageDataFromLocation(state, location);
	const currentPage = state?.page?.currentPage;
	const urlSlugsLanguageMap = pageData?.urlSlugs;
	const host = getHost(state);

	return {
		urlSlugsLanguageMap,
		supportedLanguages: pageData?.supportedLanguages,
		languageMap,
		paramLang: ownProps.paramLang,
		currentPage,
		host,
	};
};

const mapDispatchToProps = {
	onChangeLanguage: changeLanguage,
};

const LanguageSelectorList = connect(
	mapStateToProps,
	mapDispatchToProps,
)(LanguageList);

export default LanguageSelectorList;
